.App {
  height: 100vh;
  width: 100vw;
  background: rgb(61, 61, 61);
  font-family: 'Poppins', sans-serif;
}

.flex-col {
  display: flex;
  flex-direction: column;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.pos-rel {
  position: relative;
}

.full-width {
  width: 100%;
  height: 100%;
}

.pos-abs {
  position: absolute;
}