.tile {
    background-color: rgb(39, 39, 39);
    color: aliceblue;
}

.middle-col {
    gap: 100px;
}

.bug {
    width: 100px;
    height: 100px;
}

.head {
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
}

.tail {
    width: 0;
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 100px solid;
    background-color: transparent !important;
}

.left {
    transform: rotate(90deg);
}

.right {
    transform: rotate(-90deg);
}

.top {
    transform: rotate(180deg);
}

.show {
    visibility: visible;
}

.hide {
    visibility: hidden;
}

.rotate {
    background-color: cyan;
    border-radius: 50px;
    width: 20px;
    height: 20px;
    text-align: center;
    padding-bottom: 3px;
}

.rotate:hover {
    cursor: pointer;
    color: rgb(255, 255, 255);
    background-color: rgb(65, 65, 65);
}

.rotate-0 {
    transform: rotate(0deg);
}

.rotate-1 {
    transform: rotate(90deg);
}

.rotate-2 {
    transform: rotate(180deg);
}

.rotate-3 {
    transform: rotate(270deg);
}

.whole-tile {
    position: relative;
    display: flex;
}

.rotation-items {
    position: absolute;
    display: flex;
    height: 100%;
    width: 100%;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.center-items {
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.rotation-items:hover {
    cursor: pointer;
    background-color: rgba(240, 248, 255, 0.171);
}

.clicked {
    background-color: rgba(0, 225, 255, 0.205) !important;
}

.red {
    background-color: red;
    border-bottom-color: red;
}

.orange {
    background-color: orange;
    border-bottom-color: orange;
}

.purple {
    background-color: purple;
    border-bottom-color: purple;
}

.yellow {
    background-color: yellow;
    border-bottom-color: yellow;
}

@media screen and (max-width: 1000px){
    .bug {
        width: 80px;
        height: 80px;
    }

    .middle-col {
        gap: 80px;
    }
    
    .tail {
        width: 0;
        height: 0;
        border-left: 40px solid transparent;
        border-right: 40px solid transparent;
        border-bottom-width: 80px
    }
}

@media screen and (max-width: 850px){
    .bug {
        width: 60px;
        height: 60px;
    }
    
    .middle-col {
        gap: 60px;
    }

    .tail {
        width: 0;
        height: 0;
        border-left: 30px solid transparent;
        border-right: 30px solid transparent;
        border-bottom-width: 60px
    }
}

@media screen and (max-width: 620px){
    .bug {
        width: 50px;
        height: 50px;
    }
    
    .middle-col {
        gap: 50px;
    }

    .tail {
        width: 0;
        height: 0;
        border-left: 25px solid transparent;
        border-right: 25px solid transparent;
        border-bottom-width: 50px
    }
}

@media screen and (max-width: 500px){
    .bug {
        width: 45px;
        height: 45px;
    }
    
    .middle-col {
        gap: 45px;
    }

    .tail {
        width: 0;
        height: 0;
        border-left: 22.5px solid transparent;
        border-right: 22.5px solid transparent;
        border-bottom-width: 45px
    }
}


@media screen and (max-width: 440px){
    .bug {
        width: 40px;
        height: 40px;
    }
    
    .middle-col {
        gap: 40px;
    }

    .tail {
        width: 0;
        height: 0;
        border-left: 20px solid transparent;
        border-right: 20px solid transparent;
        border-bottom-width: 40px
    }
}

@media screen and (max-width: 400px){
    .bug {
        width: 35px;
        height: 35px;
    }
    
    .middle-col {
        gap: 35px;
    }

    .tail {
        width: 0;
        height: 0;
        border-left: 17.5px solid transparent;
        border-right: 17.5px solid transparent;
        border-bottom-width: 35px
    }
}

@media screen and (max-width: 340px){
    .bug {
        width: 30px;
        height: 30px;
    }
    
    .middle-col {
        gap: 30px;
    }

    .tail {
        width: 0;
        height: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
        border-bottom-width: 30px
    }
}